.detail-box {
  .image-box {
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #eaecef;
    overflow: hidden;
    box-shadow: 0px 3px 25px rgb(9 30 66 / 9%), 0px 2px 5px rgb(9 30 66 / 5%);
    img {
      border-radius: 2px;
      width: 100%;
    }
  }
  .image-detail-box {
    // margin-bottom: 30px;
    padding-left: 0 !important;
  }
  .name-product {
    margin-bottom: 28px;
  }
}

.infor-product {
  background-color: white;
  border-radius: 8px;
  padding: 30px !important;
  border: 1px solid #eaecef;
  box-shadow: 0px 3px 25px rgb(9 30 66 / 9%), 0px 2px 5px rgb(9 30 66 / 5%);
  > div {
    flex: 1;
  }

  .buy {
    background: linear-gradient(31.65deg, #deb856 6.17%, #fde29b 93.12%);
    box-shadow: 0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31);
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    color: #242424;
    text-transform: uppercase;
    float: right;
    margin-left: 10px;
  }
  .name-product {
    font-size: 32px;
    line-height: 1.2em;
    font-weight: 600;
    color: #17204d;
    margin-bottom: 1rem;
  }
  .description-title {
    font-size: 16px;
    color: #17204d;
    font-weight: 600;
    line-height: 1.5em;
    margin-bottom: 0.5em;
  }
  .description {
    font-size: 14px;
    max-height: 132px;
    height: 132px;
    overflow-y: auto;
  }

  .infor-product {
    .page-name {
      margin-left: 10px;
    }
    .ant-btn {
      margin-left: auto;
      margin-top: 7px;
      width: 89px;
      padding: 7px 15px;
      line-height: 12px;
      font-size: 14px;
      height: 31px;
      background-color: black;
      color: #fff;
      img {
        width: 13px;
        margin-bottom: 2px;
        margin-right: 3px;
      }
    }
  }
  .content-product {
    border-radius: 5px;
    .campaign {
      .campagin-value {
        color: #3d92e1;
      }
      p {
        line-height: 15px;
      }
    }
    .time {
      .time-value {
      }
      p {
        line-height: 15px;
      }
    }
  }
  .price-product {
    .price-txt {
      font-size: 16px;
      font-weight: bold;
      color: #949496;
      line-height: 20px;
      margin: 0px;
    }
    .price-value {
      margin-left: auto;
      flex-direction: column;
      color: #c8aa5d;
      font-size: 30px;
      line-height: 1em;
      font-weight: 600;
      margin: 0px;
    }
  }
}

.content-product::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: #f5f5f5;
}

.content-product::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.content-product::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4a5568;
}

.series-content {
  background-color: #ffffff;
  padding: 30px !important;
  margin-top: 15px !important;
  margin-bottom: 30px;
  border: 1px solid #eaecef;
  border-radius: 8px;
  box-shadow: 0px 3px 25px rgb(9 30 66 / 9%), 0px 2px 5px rgb(9 30 66 / 5%);
  .drop-item {
    padding: 12px 0px !important;
    .drop-amount-item {
      padding: 0px 10px;
    }
  }
  img {
    box-shadow: 0px 10px 10px -10px rgb(0 39 85 / 50%), 0px 6px 20px rgb(0 39 85 / 35%);
    margin-bottom: 10px;
    border-radius: 8px;
  }

  .title-series-content {
    font-size: 14px;
    font-weight: 700;
    color: #242424;
    padding: 0 10px;
  }
  .table-series {
    table {
      thead {
        background-color: #f5f5f5;
      }
    }
  }
}

.drop-items {
  border-radius: 0px;
  .drop-item-title {
    font-weight: 700;
    font-size: 32px;
    color: #242424;
    margin-top: 40px;
  }
  .btn-showmore {
    margin-top: 59px;
    margin-bottom: 40px;
    background: #242424;
    border-radius: 4px;
    width: 142px;
    height: 51px;
    font-size: 16px;
    color: #ffffff;
  }
}

.about-artist {
  min-height: 320px;
  background-color: #fff;
  color: #fff;
  font-size: 14px;
  margin-bottom: 70px;
  .img-artist {
    width: 35%;
    padding: 20px 40px 20px 20px;
    .img-art {
      width: 100%;
      object-fit: cover;
    }
  }
  h2 {
    color: #17204d;
    font-size: 29px;
    font-weight: bold;
    line-height: 50px;
  }
  h3 {
    color: #17204d;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1em;
    padding-bottom: 10px;
    border-bottom: 1px solid #5e6484;
    display: inline-block;
    font-weight: 600;
  }
  .about-artist-tt {
    font-weight: bold;
    font-size: 32px;
    border-bottom: 1px solid #676767;
    margin-bottom: 20px;
  }
  .artist-subject {
    margin-top: 10px !important;
  }
  .name-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .name {
      font-size: 25px;
      line-height: 45px;
    }
  }
  .artist-content {
    color: #949496;
    margin-top: 21px;
  }
  .view-profile {
    button {
      background-color: #d0b674;
      width: 115px;
      height: 40px;
      margin-top: 20px;
    }
  }
}

.more-this-artist {
  .more-this-art {
    font-size: 32px;
    color: #242424;
    margin-top: 40px;
    margin-bottom: 30px;
  }
}

.card-item:hover {
  cursor: pointer;
  margin: -4px;
  padding: 14px;
  box-shadow: 0px 6px 20px rgba(32, 44, 55, 0.45);
  transition: all 0.2s ease 0s;
}

.card-item {
  padding: 10px;
}

.infor-page {
  .open-info {
    display: flex;
    width: 50%;
    img {
      width: 48px;
    }
  }
}

.group-btn-box {
  margin-top: 30px;
  direction: ltr;
  button {
    height: 51px !important;
    width: 50% !important;
    margin-top: 0px !important;
  }
}

.group-btn-box {
  button.btn-lootbox.btn.btn-secondary {
    background: #ebecf0;
    margin-right: 5px;
    &:hover {
      color: unset;
    }
  }
  button.buy {
    margin-left: 5px;
  }
  button {
    color: #242424;
    font-weight: 600 !important;
  }
}

//response sive
@media (min-width: 1024px) {
}

@media (min-width: 768px) and (max-width: 1023px) {
}

@media (max-width: 767px) {
  .image-detail-box {
    .image-box {
      padding: 40px;
      img {
        height: 100%;
      }
    }
  }
  .series-content {
    .title-series-content {
      margin-left: 0;
    }
    .table-series {
      margin-left: 0;
    }
  }
}

.author-infor {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  border-radius: 20px;
  padding: 0 10px;
  border: 1px solid #7a7f9a;
  margin-bottom: 15px;
  .author {
    display: flex;
    align-items: center;
    line-height: 50px;
    height: 50px;
    img {
      margin-right: 8px;
    }
  }
  .address {
    display: flex;
    align-items: center;
    img {
      margin-left: 8px;
      cursor: pointer;
      &:active {
        transform: scaleX(1.1);
      }
    }
  }
}

.detail-footer {
  bottom: 40px;
  left: 0;
  justify-content: space-between;
  width: 100%;
}

.btn_rarity_drop {
  width: 110;
  background: white !important;
  margin-bottom: 10px;
}
button {
  text-transform: uppercase;
}
