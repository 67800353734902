.detail-page {
  width: 100%;
  .banner-header {
    width: 100%;
    object-fit: cover;
    display: none;
  }
  .banner-content {
    background: #181818;
    //url(../../assets/images/star_background.png) 50% 50% no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    padding: 50px calc(50% - 670px);
    margin: 0 auto !important;
    .infor-content {
      flex-direction: row;
      display: flex;
      margin: 0px;

      .left {
        padding: 30px 50px 30px 30px;
        .box-left {
          padding: 30px;
          background: #232323;
          height: 100%;
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          .title {
            color: #ffd362;
            font-size: 20px;
            line-height: 27px;
            font-weight: 600;
            margin: 10px 0;
          }
          .text-intro {
            font-size: 14px;
            line-height: 1.5em;
            color: #fff;
            text-align: justify;
          }
          .table {
            background: #2b2b2b;
            align-self: flex-end;
            flex: 1;
            flex-direction: column;
            margin: 0px !important;
            border-radius: 8px;
            .total-table {
              display: flex;
              align-item: center;
              text-align: right;
              font-size: 14px;
              line-height: 1em;
              color: #ffd362;
              padding: 10px 0px;
              font-weight: 400;
              flex: 1;
              align-items: center;
              .rarity {
                width: 25%;
                text-align: start;
              }
              .amount {
                width: 28%;
              }
              .total {
                width: 22%;
              }
              .probability {
                width: 22%;
              }
            }
            .value-table {
              display: flex;
              flex-direction: column;
              padding: 10px 20px;
              height: calc(100% - 40px);
              .rarity_btn {
                border-radius: 25px;
                height: 20px;
                line-height: 0px;
                font-size: 14px;
                background: #2b2b2b;
              }
              .rarity_legend {
                color: #ffd148;
                border-color: #ffd148;
              }
              .rarity_epic {
                color: #fc51ff;
                border-color: #fc51ff;
              }
              .rarity_super_rare {
                color: #61c4f7;
                border-color: #61c4f7;
              }
              .rarity_rare {
                color: #00e742;
                border-color: #00e742;
              }
              .rarity_common {
                color: #d8d8d8;
                border-color: #d8d8d8;
              }
              .item-table {
                display: flex;
                text-align: end;
                align-items: center;
                min-height: 54px;
                border-bottom: 1px solid #393939;
                color: #fff;
                flex: 1;
                .rarity {
                  width: 25%;
                  text-align: start;
                }
                .amount {
                  width: 28%;
                }
                .total {
                  width: 22%;
                }
                .probability {
                  width: 22%;
                }
              }
            }
            .title-table {
              background: #393939;
              padding: 10px 20px;
              display: flex;
              font-size: 14px;
              line-height: 19px;
              color: #ffffff;
              text-align: end;
              font-weight: bold;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
              font-weight: normal;
              .rarity {
                width: 35%;
                text-align: start;
              }
              .amount {
                width: 21%;
              }
              .total {
                width: 22%;
              }
              .probability {
                width: 22%;
              }
            }
          }
        }
      }
      .right {
        padding: 30px;
        .campain-start {
          color: white;
          border-radius: 10px;
          .box-right {
            .title {
              color: white;
              font-size: 36px;
              line-height: 1.5em;
              font-weight: 600;
              margin-bottom: 10px;
            }
            .lootbox-image {
              padding: 10px;
              img {
                max-width: 290px;
              }
            }
            .sub-title {
              color: white;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 1px;
              padding: 5px 0px;
            }
            .remaining {
              max-width: 330px;
              margin: 0 auto !important;
            }
            .line {
              display: flex;
              justify-content: center;
              max-width: 330px;
              margin: 0 auto !important;
              .ant-progress-text {
                color: #ccb04d;
              }
            }
            .time-zone {
              background-color: #242424;
              width: 100%;
              border-radius: 8px;
              padding: 30px;
              .btn-follow {
                width: 100%;
                height: 63px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ffca42;
                margin-top: 20px;
                color: #242424;
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
                border-radius: 4px;
              }
              .btn-sold {
                width: 100%;
                height: 63px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #979797;
                margin-top: 20px;
                color: #585858;
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
                border-radius: 4px;
              }
              img {
                object-fit: cover;
                width: 160px;
                height: 10px;
              }
              .campain-end {
                color: #767676;
                font-size: 16px;
                line-height: 1.5em;
              }
              .time-countdown {
                display: flex;
                justify-content: space-around;
                width: 100%;
                .item-time {
                  color: #fff;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 21px;
                  color: #767676;
                  .box-time {
                    border-radius: 8px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 31px;
                    text-align: center;
                    color: #fff;
                    margin-bottom: 5px;
                  }
                }
                .dot {
                  color: #fff;
                  font-size: 24px;
                  padding-top: 6px;
                  margin: 0 10px;
                }
              }
              .time {
                color: white;
                letter-spacing: 1px;
                font-size: 30px;
              }
              .note-time {
              }
              .ant-statistic-content {
                color: white;
                letter-spacing: 0.5px;
                font-size: 30px;
                font-weight: 700;
                margin-top: -6px;
              }
              .bl-r {
                justify-content: space-between;
                display: flex;
                width: 100%;
                line-height: 30px;
                padding: 20px 0;
                .title {
                  color: #767676;
                  font-size: 16px;
                  line-height: 1.5em;
                  float: right;
                }
                .box-price-title {
                  color: #767676;
                  font-size: 16px;
                  line-height: 1.5em;
                  float: right;
                }
                .price {
                  color: white;
                  letter-spacing: 0.5px;
                  font-size: 20px;
                  font-weight: 700;
                  float: right;
                }
                .total {
                  float: right;
                  color: #949496;
                }
              }
              .btn-buy {
                width: 100%;
                height: 63px;
                color: #242424;
                font-size: 20px;
                font-weight: bold;
                background-color: #ffca42;
                justify-content: center;
                display: flex;
                align-items: center;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }

  .all-list {
    padding: 0px calc(50% - 670px) 30px calc(50% - 670px);
    margin: 0px;
    position: relative;
    .drop-item-div {
      padding: 30px;
    }
  }
  .about-artist {
    background-color: white;
    width: 100%;
    padding: 30px calc(50% - 640px);
    margin-bottom: 50px !important;
    margin: 0px;

    > div {
      padding: 30px;
      box-shadow: 0px 6px 20px rgba(32, 44, 55, 0.15);
      margin: 0px;
      border: 1px solid #dde3f2;
      border-radius: 8px;
    }

    .img-artist {
      width: 35%;
      padding: 0px 40px 0px 0px;
      .img-art {
        width: 100%;
        // object-fit: cover;
        border-radius: 8px;
      }
    }
    h2 {
      color: #17204d;
      font-size: 29px;
      line-height: 1.3em;
      font-weight: 600;
    }
    h3 {
      color: #17204d;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 1em;
      padding-bottom: 10px;
      border-bottom: 1px solid #5e6484;
      display: inline-block;
      font-weight: 600;
    }
    .text-artist {
      width: 65%;
      padding: 0px 0px 0px 20px;
    }
    .divider {
      width: 70%;
      height: 2px;
      background-color: #676767;
      margin: 10px 0px;
    }
    .infor-art {
      display: flex;
      flex-direction: row;
      color: white;
      margin: 30px 0;
      span {
        line-height: 2.5;
      }
      .name-art {
        font-size: 16px;
        font-weight: 600;
        margin-left: 20px;
      }
    }
    .intro-art {
      color: #7a7f9a;
      font-size: 14px;
      line-height: 20px;
      margin-top: 10px;
    }
    .btn-profile {
      width: 115px;
      height: 40px;
      background-color: #d0b674;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #242424;
      font-size: 14px;
      font-weight: 700;
      margin-top: 30px;
      cursor: pointer;
      border-radius: 6px;
    }
  }
}
@media (max-width: 448px) {
  .campain-start {
    margin: 30px 5px !important;
    .box-right {
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
  .banner-content {
    height: 1600px !important;
  }
  .infor-content {
    flex-direction: column !important;
    width: 98% !important;
    top: 42% !important;
    .left {
      width: 100% !important;
    }
    .right {
      width: 100% !important;
    }
  }
  .detail-page {
    .all-list {
      margin: 20px 5px;
      .about-artist {
        .img-artist {
          width: 100%;
        }
        .text-artist {
          width: 100%;
          padding: 10px;
        }
      }
    }
    .banner-header {
      display: none;
    }
  }
  .box-left {
    padding: 5px !important;
  }
  .about-artist {
    div {
      flex-direction: column;
    }
  }
}

.time-down-start-end {
  background-color: #242424;
  width: 100%;
  line-height: 39px;
  padding: 10px 20px;
  justify-content: space-between;
  border-radius: 8px;
  // .time-down{
  //   display: flex;

  // }
  .time-countdown {
    font-size: 25px;
    font-weight: bold;
    display: flex;
  }
  .box-countdown {
    line-height: '39px';
  }
}

.currency-select {
  width: 100%;
  height: 32px;
  background: #242424;
  border: 1px solid #666666;
  color: white !important;
  border-radius: 3px;
  .ant-select-selector {
    background: #242424 !important;
  }
}
.currency-text {
  color: #767676;
}
