.slick-slider .slick-prev {
  left: 10px !important;
  z-index: 1 !important;
  background: transparent !important;
}
.slick-slider .slick-next {
  right: 10px !important;
  z-index: 1 !important;
  background: transparent !important;
}
.wrap {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  float: left;
  border-radius: 4px;
  padding: 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  .container {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: scroll;

    .info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .avatar {
        padding: 10px;
        display: flex;
        justify-content: center;
        background-color: #f9f9f9;
        width: 50%;
        height: 100%;
        img {
          width: 100%;
          cursor: pointer;
          margin: 0 auto;
        }
      }
      .profile {
        width: 50%;
        padding: 10px 10px 10px 40px;
        .caption {
          margin-right: 5px;
        }
        .title {
          font-weight: bold;
          font-size: 26px;
          line-height: 1.3em;
          color: #242424;
          word-break: break-all;
        }
        .desc {
          margin-top: 5x;
          font-weight: bold;
          font-size: 16px;
          line-height: 1.2em;
          color: #242424;
          word-break: break-all;
        }
        .value {
          margin-top: 5px;
          color: #585858;
          display: flex;
          word-break: break-all;
          .name-campain {
            color: #ec4695;
            margin-left: 5px;
            word-break: break-all;
            width: 70%;
          }
          .bold {
            font-weight: 700;
            margin-left: 5px;
            width: 70%;
          }
        }
      }
    }
    .list-img {
      margin-top: 40px;
      height: calc(45% - 40px);
      .title-list {
        margin-bottom: 10px;
      }
      .list {
        height: calc(100% - 40px);
        .img-slide {
          max-width: 100%;
          display: inline-block;
          height: 100%;
        }
        .slick-slider {
          height: auto;

          button {
            background-color: #242424;
          }
          .slick-slide {
            height: auto;
            padding: 10px;
          }
          .slick-list {
            height: 100%;
            .slick-track {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: stretch;
              height: 100%;
            }
          }
        }
      }
      .title-list {
        font-weight: bold;
        padding-left: 10px;
        font-size: 20px;
        line-height: 27px;
        color: #242424;
      }
    }
  }
}
@media (max-width: 448px) {
  .wrap {
    width: 95%;
    padding: 10px;
    overflow-y: auto;
    img {
      width: 80% !important;
      object-fit: contain;
    }
    .container {
      .list-img {
        margin-top: 20px;
      }
      .info {
        flex-direction: column;
        height: auto;
        .avatar {
          width: 100%;
        }
        .profile {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}
