.product-detail-page {
  background-color: #f9f9f9;
  font-size: 14px;
  padding-top: 30px;
  .container {
    padding-bottom: 50px;
  }
  .table thead th,
  .table thead tr {
    border: none !important;
  }
}
.btn-handle {
  border-radius: 50px !important;
}
.series-content {
  .ant-card-body {
    width: 100%;
    padding: 10px;
  }
}
.btn-acctive {
  background: #201934 !important;
  color: #fff !important;
}
